// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  env: "dev",
  apiUrls: {
    
  //  api: "http://127.0.0.1:8000",
   // api: 'http://dev.floofster.in:8000'
  api: 'http://dev.floofster.in'
   // api: 'http://qa.floofster.in'
  // api: 'https://dev.wagkart.com'
  },

   firebaseConfig: {
     apiKey: "AIzaSyBycJU0tK5VGwEhabBQYYRMaQLy87bydkQ",
    authDomain: "wagekart.firebaseapp.com",
    databaseURL: "https://qa-wagekart-a44d7.firebaseio.com",     
     projectId: "wagekart",
     storageBucket: "wagekart.appspot.com",
     messagingSenderId: "960444920458",
     appId: "1:960444920458:web:eb005869a31a2f12dd7373",
     measurementId: "G-XGYCVJC477"
   }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
