
export class WalkingAPIConstants {   
    //Addtiional request   
    static  ADMIN_REQUESTS = 'walking/adminRequests';
    // Analytics dashboard
    static ANALYTICS_GET_GROUP = 'walking/analytics/getGroup';
    static ANALYTICS_GET_VALUE = 'walking/analytics/getValue';
    static GET_ANALYTICS_REPORT = 'walking/analytics/getAnalyticReport';
    static GET_ANALTYICS_LEAD_BAR_DATA ='analytics/getLeadBarData' ;
    static GET_ANALTYICS_LINE_CHART_DATA = 'walking/analytics/getLineChartData';

    //ADMINS
    static ADMINS = 'walking/admins'

    //Area Order  
    static AREA_ORDER = 'walking/areaOrder';
    static OWNERADDRESS = 'walking/address/getOwnerAddresses';

    //WALKERS
    static WALKERS = 'walking/walkers';
    static WALKER_ATTENDANCE = 'walking/walkers/attendence' ;
    static WALKER_ATTENDANCES =  'walking/walkerAttendances';
    static WALKER_LIST ='walker/list'
    static ACTIVE_WALKRES = 'walking/walkers/activeWalkers';
    //CONVEYANCE
    static CONVEYANCE = 'walking/walking/walkers/conveyance';
    static CONVEYANCE_TWO = 'walking/walking/walkers/conveyanceTwo';
    static CLOCK_IN_OUT = 'walking/walking/walkers/clockIn'; 

    //EXCEL
    static EXCELS = "excels";

    //Banner
    static BANNER = 'walking/banners' ;

    //PAYMENT_ORDERS
    static CREATE_ORDER = 'walking/paymentHistories/create/order' ;

    //SERVICEABLE_PINCODES 
    static SERVICEABLE_PINCODES = 'walking/serviceablePincodes';

    //Subscriptions
    static SUBSCRIPTIONS = 'walking/subscriptions';
    static BOOOKING_INFO = 'walking/bookinginfos';
    static TRIAL_BOOKING = 'walking/subscriptions/trial/booking';
    static TRIAL_WALK_FEEDBACK= 'walking/trialWalkFeedback';
    static GET_TRIAL_DATE = 'walking/get';
    static RENEW_BY_ADMIN = 'walking/subscriptions/renewByAdmin';
    static EXTEND_CURRENT_PACKAGE = 'walking/subscriptions/extendCurrentPackage';
    static CREATE_EXTRA_WALKS = 'walking/subscriptions/createExtraWalk';
    static FOLLOW_UP_DATE = 'walking/subscriptions/followUpDate';
    static SUBSCRIPTION_HISTORY = 'walking/subscriptions/getSubsHistory';
    static SUBSCRIPTION_INFO_SOURCES = 'walking/subscriptions/infoSources';
    static SUBSCRIPTION_WALK_DURATION_UPDATE = 'walking/subscriptions/updateWalkDuration';
    static WALKS_LAST_TIME_SLOT = 'walking/walks/lastTimeSlot';
    static PAYMENT_REMINDER = 'walking/subscriptions/updatePaymentReminder';
    static SUBSCRIPTION_EXPIRY_DATE = 'walking/expiry/date'
    static PAUSE_REQUESTS = 'walking/pauseRequests'
    static SUBSCRIPTION_EDIT_DETAILS = 'walking/subscriptions/editDetails'
    static SUBSCRIPTION_SUBCOUNT = 'walking/subscriptions/subCount';
    static SUBSCRIPTION_TRIAL_STATS= 'walking/subscriptions/getTrialStatistics'
    static SUBSCRIPTION_NUMBER_OF_WALK = 'walking/subscriptions/NoofWalks'
    static SUBSCRIPTION_LOST_TRIAL = 'walking/subscriptions/lostTrial'

    //CHATS (check if it needs to be inside walking folder or should be outside)
    static BROADCAST_NOTIFICATION = 'walking/admins/broadcast/notification';
    static UPDATE_LAST_MESSAGE = 'walking/last/message/';
    static COUNT_UNREAD_CHATS = 'walking/total/unread';

 
    //CONGIGURATIONS
    static CONFIGURATIONS = 'walking/configurations';

    //USER_EQUIRIES
    static WEBSITE_ENQUIRIES = 'walking/userEnquiries';


    //DEWORMING
    static DEWORMING = 'walking/dewormings';
    static DEWORMING_DATA = 'walking/dewormings/getDewormingDataDogID';

    //DOG_VACCINATIONS
    static DOG_VACCINATIONS = 'walking/dogVaccinations';
    static DOG_VACCINE_DATA ='dogVaccinations/getDogHealthRecord';
    static MEDICAL_HISTORY = 'walking/dogVaccinations/getMedicalHistory';
    static SEARCH_BY_HEALTH_RECORD = 'walking/dogs/searchByHealthRecord';
    static VACCINE_NAMES = 'walking/vaccines/vaccineNames';

    //DOGS
    static DOGS = 'walking/dogs';
    static BREEDS = 'walking/breeds' ;

    //FAQs
    static FAQs = 'walking/faqs';

    //GRID_SERVICES
    static GRID_SERVICES = 'walking/gridService';
 
    //LEAD_CRMS
    
    static LEAD_CRMs = 'walking/leadCrms' ; 
    static UPDATE_TOP_LEADS = 'walking/leadCrms/updateTopLead';
    static IMPORT_LEADS =  'createData';
    static PREFERENCES = 'walking/leadCrms/preferences';
    static UPDATE_PREFERENCES = 'walking/leadCrms/updatepreferences';
    static CALL_STATUS = 'walking/leadCrms/callstatus';
    static UPDATE_CALL_STATUS = 'walking/leadCrms/updatecallstatus';
    static LEAD_FOLLOWUP_DATE = 'walking/leadCrms/leadfollowUpDate';
    static LEAD_PLATFORMS = 'walking/leadCrms/platforms';
    static UPDATE_PLATFORMS =  'leadCrms/updateplatforms';
    static LEAD_TYPES = 'walking/leadCrms/leadTypes';
    static UPDATE_LEAD_TYPE = 'walking/leadCrms/updateleadTypes';
    static LEAD_STATUS= 'walking/leadCrms/leadStatuss';
    static UPDATE_LEAD_STATUS= 'walking/leadCrms/updateleadStatuss';
    static UPDATE_LOST_SUBTYPE = 'walking/leadCrms/updateLostSubtypes';
    static ADMIN_NOTE = 'walking/leadCrms/adminNote';
    static PHONE = 'walking/leadCrms/phone';
    static CITY = 'walking/leadCrms/city';
    static AREA = 'walking/leadCrms/area';
    static NAME = 'walking/leadCrms/name';
    static EMAIL = 'walking/leadCrms/email';
    static ADDRESS = 'walking/leadCrms/address';
    static PINCODE = 'walking/leadCrms/pincode';
    static GET_LEAD_HISTORY  = 'walking/leadCrms/getleadHistory';
    static UPDATE_TOP_LEAD =    'leadCrms/updateTopLead';
    static UPDATE_ATTEMPTS = 'walking/leadCrms/updateAttempts';
    static UPDATE_FIRST_CALL ='leadCrms/updatefirstcall';
    static UPDATE_DATE_OF_CALL = 'walking/leadCrms/updatedateofcall'
    static UPDATE_TIME_OF_CALL = 'walking/leadCrms/updatetimeofcall';
    static UPDATE_CALLER = 'walking/leadCrms/updateCaller';
    static UPDATE_FOLLOW_UP_DATE = 'walking/leadCrms/updatefollowtype';
    static COUNT_TODAYS_FOLLOW_UP = 'walking/leadCrms/counttodaysfollowupdate';
    static COUNT_DATE_OF_CALL = 'walking/leadCrms/countdateofcall';
    static GET_LEAD_DATA = 'walking/leadCrms/getLeadData';
    static CREATE_PAYMENT_LINK = 'walking/create/paymentLink';
    static UPDATE_SERVICE_REQUIRED = 'walking/leadCrms/updateServiceRequired';

    //NOTIFICATIONS
    static NOTIFICATIONS = 'walking/notifications';

    //OWNERS 
    static OWNERS = 'walking/owners';
    static OWNERS_DETAILS = 'walking/owners/small';

    //PLANS
    static PLANS = 'walking/plans';
    static DEFAULT_PLANS_CHECK = 'walking/plans/check/default';
    static PLANS_V2 = 'walking/v2/plans';
    //PLANS
    static PLANS_PER_CITY = 'walking/plans/cities';

    static PROMO_CODES = 'walking/promocodes';
   
    //TIMESLOTS
    static GET_TIME = 'walking/get/time';
    static TIME_SLOTS = 'walking/timeslots';
    static CHECK_TIME_SLOTS = 'walking/timeslots/check';
    static ALL_TIMESLOTS = 'walking/timeslots/allslots';

    //TRANSLATION
    static TRANSLATE = 'walking/transliterates';

    //WALKS
    static WALKS = 'walking/walks' ;
    static END_WALK = 'walking/endWalk';
    static START_WALK = 'walking/startWalk';    
    static WALK_STATISTICS  = 'walking/walks/getWalksStatistics' ;
    static WALK_EXTENTION_REASON = 'walking/walks/getExtendReason';

    //FIREBASE_WAKLS
    static GET_WALKS_IN_FIREBASE = 'walking/getWalksFirebase';
    static DELETE_FIREBASE_WALKS = 'walking/deleteWalksFirebase';

    //EARNINGS
    static WALKER_EARNINGS = 'walking/earnings';
    static WALKER_COMPLAINT = 'walking/earnings/walkerComplaint';
    static WALKER_WALLET_DETAILS = 'walking/walkerWallet/getWalkerWalletDetails';
    static WALKER_EARNING_SUMMARY = 'walking/walkerWallet/getWalkerEarningSummary';
    static ADDITIONAL_EARNING = 'walking/walkerWallet/additionalEarning';
    static PAYOUT = 'walking/walkerWallet/payout';
}