import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { User } from '../../model/walking/user.model';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Filters } from '../../http-handler/common/contracts/filters';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    public _snackBar: MatSnackBar,
    public router: Router,
    private http: HttpClient,
  ) { }

  toster(message: string, duration?: number) {
    this._snackBar.open(message, 'Close', {
      panelClass: ['success'],
      duration: duration || 4000,
    });
  }

  error(message: string, duration?: number) {
    this._snackBar.open(message, 'Close', {
      panelClass: ['warning'],
      duration: duration || 4000,
    });
  }

  setFilters(filters:Filters, newFilters:{ [key:string]:any }){
    Object.keys(newFilters).forEach(field => {
      if(field in filters.properties){
        filters.properties[field].value = newFilters[field];
      }
    })
  }

  valueToBeUpdate(newObject: any, oldObject: any) {
    let updatedObject: any = {};
    Object.keys(newObject).forEach((k) => {
      if (oldObject[k]) {
        if (typeof newObject[k] == 'object' && typeof oldObject[k] == 'object') {
          const obj = this.valueToBeUpdate(newObject[k], oldObject[k]);
          if (!this.isObjectEmpty(obj)) updatedObject[k] = obj;
        } else {
          if (newObject[k] !== oldObject[k]) {
            updatedObject[k] = newObject[k]
          }
        }
      } else {
        updatedObject[k] = newObject[k]
      }
    });

    return updatedObject
  }

  isObjectEmpty(object: any) {
    for (let obj in object) {
      if (object[obj]) {
        return false
      }
    }
    return true
  }

  formGroupSetValue(form: UntypedFormGroup, value: { [key: string]: any }) {
    Object.keys(form.controls).forEach(key => {
      const control = form.get(key);
      if (value[key] !== undefined) {
        if (control instanceof UntypedFormControl) {
          control.setValue(value[key]);
        } else if (control instanceof UntypedFormGroup) {
          this.formGroupSetValue(control, value[key])
        } else if (control instanceof UntypedFormArray) {
          control.controls.forEach((arrayControl, index) => {
            const arrayItem: Array<any> = value[key][index];
            if (arrayControl instanceof UntypedFormControl) {
              arrayControl.setValue(arrayItem);
            } else if (arrayControl instanceof UntypedFormGroup) {
              this.formGroupSetValue(arrayControl, arrayItem);
            }
          })
        }

      }
    })
  }


  get token() {
    return localStorage.getItem('token') || '';
  }

  set token(token: string) {
    localStorage.setItem('token', token);
  }

  get user() {
    return JSON.parse(localStorage.getItem('user') as any) || new User();
  }

  set user(u: User) {
    localStorage.setItem('user', JSON.stringify(u));
  }

  clearAdmin() {
    localStorage.clear();
    this.toster('Logout Successfully.');
    this.router.navigate(['/auth/login']);
  }

}
