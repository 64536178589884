export class User{
    id?: number;
    name?: string; 
    email?: string; 
    phone?: string;
    role?: string;
    createdBy?: string
    status?: string;
    imgUrl?: any;
    token?: string;
    createdAt?: string;
    updatedAt?: string;
}