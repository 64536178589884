import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IApi } from '../../http-handler/common/contracts/api';
import { GenericApi } from '../../http-handler/common/generic-api';
import { User } from '../../model/walking/user.model';
import * as moment from 'moment';
import { AppService } from './app.service';
import { WalkingAPIConstants } from './_walkingApiPathConstants';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  users: IApi<User>;
  exceldata: IApi<any>;


  constructor(
    http: HttpClient,
    private appService: AppService
  ) {
    this.users = new GenericApi<User>(WalkingAPIConstants.ADMINS, http);
    this.exceldata = new GenericApi<any>(WalkingAPIConstants.EXCELS, http);
  }

  exportList(data: any) {
    console.log(data);
    const exportData: any[] = [];
    data.forEach((c: any) => {
      const cData = {
        Type: c?.type=='contactWhenAvailable'?'Owner':'' || c?.type=='visitLater'?'Owner':'' || c?.type=='owner'?'Owner':'' || c?.type=='walker'?'Walker':'',
        AssociatedPincode: c?.pincode,
        Name: c?.name,
        Address: c?.address || '',
        RequestType: c?.type=='contactWhenAvailable'?'Notify Me':'' || c?.type=='visitLater'?'Visit Later':'' || c?.type=='owner'?'Booked':'' || c?.type=='walker'?'--':''
      }
      exportData.push({
        ...cData
      });
    })
    console.log(exportData);  
    this.exportRequest(exportData, `MapDensityList_${moment(new Date()).format('DD-MM-YYYY')}_${moment(new Date()).format('H:mm')}`);
  }

  exportRequest(data: any, name: any) {
    this.exceldata.create({ data, name }, 'from/data').then((d: any) => {
      console.log(d.url);
      this.appService.toster('Data exported successfully');
      window.open(d.url, "_blank");
    }).catch((err: any) => {
      this.appService.error(err);
    })
  }

}


