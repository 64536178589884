import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject, Observable, Subscription } from 'rxjs'
import { AppService } from './app.service';
import { UserService } from './user.service';

@Injectable()
export class MessagingService {
    currentMessage = new BehaviorSubject(null);

    constructor(private angularFireMessaging: AngularFireMessaging,
        private userService: UserService) {
        this.angularFireMessaging.messages.subscribe(d => {
        })

        // messaging.subscribe(
        //     (_messaging) => {
        //         _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        //         _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        //     }
        // )
    }

    requestPermission(callback?: { (token: any): void; (arg0: string): void; }) {
        return this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                if (callback) callback(token)
                return token;
            },
            (err) => {
                console.error('Unable to get permission to notify.', err);
                return err;
            }
        );
    }

    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload: any) => {
                const no = new Notification(payload.notification.title, {
                    body: payload.notification.body
                });
                this.currentMessage.next(payload);
                // this.userService.updateLocalUser();
                no.onclick = function () {
                    window.focus();
                }
            })
    }
}